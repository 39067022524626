import * as _xtend2 from "xtend";

var _xtend = "default" in _xtend2 ? _xtend2.default : _xtend2;

import * as _fuzzy2 from "fuzzy";

var _fuzzy = "default" in _fuzzy2 ? _fuzzy2.default : _fuzzy2;

import _list from "./list";
var exports = {};
var extend = _xtend;
var fuzzy = _fuzzy;
var List = _list;

var Suggestions = function (el, data, options) {
  options = options || {};
  this.options = extend({
    minLength: 2,
    limit: 5,
    filter: true,
    hideOnBlur: true,
    noInitialSelection: true
  }, options);
  this.el = el;
  this.data = data || [];
  this.list = new List(this);
  this.query = "";
  this.selected = null;
  this.list.draw();
  this.el.addEventListener("keyup", function (e) {
    this.handleKeyUp(e.keyCode, e);
  }.bind(this), false);
  this.el.addEventListener("keydown", function (e) {
    this.handleKeyDown(e);
  }.bind(this));
  this.el.addEventListener("focus", function () {
    this.handleFocus();
  }.bind(this));
  this.el.addEventListener("blur", function () {
    this.handleBlur();
  }.bind(this));
  this.el.addEventListener("paste", function (e) {
    this.handlePaste(e);
  }.bind(this)); // use user-provided render function if given, otherwise just use the default

  this.render = this.options.render ? this.options.render.bind(this) : this.render.bind(this);
  this.getItemValue = this.options.getItemValue ? this.options.getItemValue.bind(this) : this.getItemValue.bind(this);
  return this;
};

Suggestions.prototype.handleKeyUp = function (keyCode, e) {
  // 40 - DOWN
  // 38 - UP
  // 27 - ESC
  // 13 - ENTER
  // 9 - TAB
  if (keyCode === 40 || keyCode === 38 || keyCode === 27 || keyCode === 9) return;

  if (keyCode === 13) {
    if (this.list.items[this.list.active]) {
      this.list.handleMouseUp(this.list.items[this.list.active]);
      e.stopPropagation();
    }

    return;
  }

  this.handleInputChange(this.el.value);
};

Suggestions.prototype.handleKeyDown = function (e) {
  switch (e.keyCode) {
    case 13:
      // ENTER
      if (this.list.active >= 0) {
        this.list.selectingListItem = true;
      }

      break;

    case 9:
      // TAB
      if (!this.list.isEmpty()) {
        if (this.list.isVisible()) {
          e.preventDefault();
        }

        this.value(this.list.active >= 0 ? this.list.items[this.list.active].original : null);
        this.list.hide();
      }

      break;

    case 27:
      // ESC
      if (!this.list.isEmpty()) this.list.hide();
      break;

    case 38:
      // UP
      this.list.previous();
      break;

    case 40:
      // DOWN
      this.list.next();
      break;
  }
};

Suggestions.prototype.handleBlur = function () {
  if (!this.list.selectingListItem && this.options.hideOnBlur) {
    this.list.hide();
  }
};

Suggestions.prototype.handlePaste = function (e) {
  if (e.clipboardData) {
    this.handleInputChange(e.clipboardData.getData("Text"));
  } else {
    var self = this;
    setTimeout(function () {
      self.handleInputChange(e.target.value);
    }, 100);
  }
};

Suggestions.prototype.handleInputChange = function (query) {
  this.query = this.normalize(query);
  this.list.clear();

  if (this.query.length < this.options.minLength) {
    this.list.draw();
    return;
  }

  this.getCandidates(function (data) {
    for (var i = 0; i < data.length; i++) {
      this.list.add(data[i]);
      if (i === this.options.limit - 1) break;
    }

    this.list.draw();
  }.bind(this));
};

Suggestions.prototype.handleFocus = function () {
  if (!this.list.isEmpty()) this.list.show();
  this.list.selectingListItem = false;
};
/**
 * Update data previously passed
 *
 * @param {Array} revisedData
 */


Suggestions.prototype.update = function (revisedData) {
  this.data = revisedData;
  this.handleKeyUp();
};
/**
 * Clears data
 */


Suggestions.prototype.clear = function () {
  this.data = [];
  this.list.clear();
};
/**
 * Normalize the results list and input value for matching
 *
 * @param {String} value
 * @return {String}
 */


Suggestions.prototype.normalize = function (value) {
  value = value.toLowerCase();
  return value;
};
/**
 * Evaluates whether an array item qualifies as a match with the current query
 *
 * @param {String} candidate a possible item from the array passed
 * @param {String} query the current query
 * @return {Boolean}
 */


Suggestions.prototype.match = function (candidate, query) {
  return candidate.indexOf(query) > -1;
};

Suggestions.prototype.value = function (value) {
  this.selected = value;
  this.el.value = this.getItemValue(value || {
    place_name: this.query
  });

  if (document.createEvent) {
    var e = document.createEvent("HTMLEvents");
    e.initEvent("change", true, false);
    this.el.dispatchEvent(e);
  } else {
    this.el.fireEvent("onchange");
  }
};

Suggestions.prototype.getCandidates = function (callback) {
  var options = {
    pre: "<strong>",
    post: "</strong>",
    extract: function (d) {
      return this.getItemValue(d);
    }.bind(this)
  };
  var results;

  if (this.options.filter) {
    results = fuzzy.filter(this.query, this.data, options);
    results = results.map(function (item) {
      return {
        original: item.original,
        string: this.render(item.original, item.string)
      };
    }.bind(this));
  } else {
    results = this.data.map(function (d) {
      var renderedString = this.render(d);
      return {
        original: d,
        string: renderedString
      };
    }.bind(this));
  }

  callback(results);
};
/**
 * For a given item in the data array, return what should be used as the candidate string
 *
 * @param {Object|String} item an item from the data array
 * @return {String} item
 */


Suggestions.prototype.getItemValue = function (item) {
  return item;
};
/**
 * For a given item in the data array, return a string of html that should be rendered in the dropdown
 * @param {Object|String} item an item from the data array
 * @param {String} sourceFormatting a string that has pre-formatted html that should be passed directly through the render function 
 * @return {String} html
 */


Suggestions.prototype.render = function (item, sourceFormatting) {
  if (sourceFormatting) {
    // use existing formatting on the source string
    return sourceFormatting;
  }

  var boldString = item.original ? this.getItemValue(item.original) : this.getItemValue(item);
  var indexString = this.normalize(boldString);
  var indexOfQuery = indexString.lastIndexOf(this.query);

  while (indexOfQuery > -1) {
    var endIndexOfQuery = indexOfQuery + this.query.length;
    boldString = boldString.slice(0, indexOfQuery) + "<strong>" + boldString.slice(indexOfQuery, endIndexOfQuery) + "</strong>" + boldString.slice(endIndexOfQuery);
    indexOfQuery = indexString.slice(0, indexOfQuery).lastIndexOf(this.query);
  }

  return boldString;
};
/**
 * Render an custom error message in the suggestions list
 * @param {String} msg An html string to render as an error message
 */


Suggestions.prototype.renderError = function (msg) {
  this.list.drawError(msg);
};

exports = Suggestions;
export default exports;