var exports = {};

var List = function (component) {
  this.component = component;
  this.items = [];
  this.active = component.options.noInitialSelection ? -1 : 0;
  this.wrapper = document.createElement("div");
  this.wrapper.className = "suggestions-wrapper";
  this.element = document.createElement("ul");
  this.element.className = "suggestions";
  this.wrapper.appendChild(this.element); // selectingListItem is set to true in the time between the mousedown and mouseup when clicking an item in the list
  // mousedown on a list item will cause the input to blur which normally hides the list, so this flag is used to keep
  // the list open until the mouseup

  this.selectingListItem = false;
  component.el.parentNode.insertBefore(this.wrapper, component.el.nextSibling);
  return this;
};

List.prototype.show = function () {
  this.element.style.display = "block";
};

List.prototype.hide = function () {
  this.element.style.display = "none";
};

List.prototype.add = function (item) {
  this.items.push(item);
};

List.prototype.clear = function () {
  this.items = [];
  this.active = this.component.options.noInitialSelection ? -1 : 0;
};

List.prototype.isEmpty = function () {
  return !this.items.length;
};

List.prototype.isVisible = function () {
  return this.element.style.display === "block";
};

List.prototype.draw = function () {
  this.element.innerHTML = "";

  if (this.items.length === 0) {
    this.hide();
    return;
  }

  for (var i = 0; i < this.items.length; i++) {
    this.drawItem(this.items[i], this.active === i);
  }

  this.show();
};

List.prototype.drawItem = function (item, active) {
  var li = document.createElement("li"),
      a = document.createElement("a");
  if (active) li.className += " active";
  a.innerHTML = item.string;
  li.appendChild(a);
  this.element.appendChild(li);
  li.addEventListener("mousedown", function () {
    this.selectingListItem = true;
  }.bind(this));
  li.addEventListener("mouseup", function () {
    this.handleMouseUp.call(this, item);
  }.bind(this));
};

List.prototype.handleMouseUp = function (item) {
  this.selectingListItem = false;
  this.component.value(item.original);
  this.clear();
  this.draw();
};

List.prototype.move = function (index) {
  this.active = index;
  this.draw();
};

List.prototype.previous = function () {
  this.move(this.active <= 0 ? this.items.length - 1 : this.active - 1);
};

List.prototype.next = function () {
  this.move(this.active >= this.items.length - 1 ? 0 : this.active + 1);
};

List.prototype.drawError = function (msg) {
  var li = document.createElement("li");
  li.innerHTML = msg;
  this.element.appendChild(li);
  this.show();
};

exports = List;
export default exports;